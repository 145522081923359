import React from 'react'
import Design from './pg3.module.css'

const Keyword = () => {
    return (
        <div className={Design.Keyword}>
          <h2>Keyword</h2>
          <p>
          After the abstract portion provide 3 to 6 appropriate keywords.
          </p>
          
        </div>
      );
}

export default Keyword
